body{
  background-color: rgb(240,240,240);
}

a{
  text-decoration: none;
  color: initial
}

#root {
  height: 100vh;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.5em !important;
}

h5{
  color: #3f3838;
}

p{
  color: #3f3838;
}

.bg-primary{
  background-color: #F79623
}

.menu-nav{
  margin-left: auto; 
  margin-right: auto;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0.5em .8em !important;
}
.err_message{
  color: orangered;
  font-size: .9rem;
}
.focused-nav{
  background-color: #FEF3E6;
  border-radius: 5px;
  border: 1px solid #F79623;
}

.balance-history{
   overflow-x: hidden !important;
}

.balance-history::-webkit-scrollbar {
  background-color: #ababab90;
  width: .6em;
  border-radius: 8px;
}

.balance-history::-webkit-scrollbar-thumb {
  background-color: #00bfb290;
  width: .4em;
  border-radius: 8px;
}

.focused-mobile-nav{
  background-color: #F79623;
  color: white
}

.mode-focused{
  background-color: #F79623;
}

.mobile-row:nth-child(odd){
  background-color: rgb(245,245,245);
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0;
}

.main-container{
  background-image: url('/public/bg2.jpg');
  background-size: cover;
  background-position: bottom right;
  min-height: 100vh;
}

/* PULSE TEXT ANIMATIONS  */
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

.text-with-cursor::after {
  content: '|';
  color: gray;
  display: inline-block;
  animation-name: blink-animation;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

@keyframes letter-animation {
  from {
    transform: scale(20) translateX(-400px);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

.slideFromLeft {
  content: '|';
  color: gray;
  display: inline-block;
  animation-name: letter-animation;
  animation-duration: 4000ms;
  animation-iteration-count: 1;
}

/*------------------------------*/
